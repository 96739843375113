.featured-title {
    color: white;
    font-size: 3.5rem;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 5px;
    position: relative;
    font-weight: 600;
    left: 0;
    top: -20px;
    margin-bottom: 10px;
}

.overview {
    color: white;
    width: min(500px, 90%);
    text-align: left;
    margin: 0 auto 30px;
}

.bold {
    font-weight: bold;
}

.experiences {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust the gap between items as needed */
}

.experience {
    flex: 1 1 calc(50% - 20px);
    /* Two items per row with gap */
    box-sizing: border-box;
    /* Ensure padding and border are included in the width and height */
}

.MuiTypography-root,
.MuiLink-root {
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiTimelineDot-root {
    background-color: white;
}

@media only screen and (min-width: 550px) {
    .experiences {
        gap: 20px;
    }
}

@keyframes glowing2 {
    0% {
        text-shadow: 0 0 5px var(--regular);
    }

    50% {
        text-shadow: 0 10 10px var(--regular);
    }

    100% {
        text-shadow: 0 0 5px var(--regular);
    }
}

/* @keyframes lineAnimation {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }

    50% {
        transform: translateX(0%);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.line {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    animation: lineAnimation 2s ease-in-out infinite;
} */