.ttt-wrapper {
    margin-top: 100px;
    color: white;
    text-align: center;
    padding: 0 60px 0;
}

.board {
    width: 180px;
    margin: auto;
}

.board_border {
    margin: auto;
    display: inline-block;
    padding: 2px;
    border: 1px solid white;
}

.cell {
    width: 60px;
    height: 60px;
    border: 0.5px solid white;
    background-color: transparent;
    top: 0px;
    margin-top: 0;
    color: white;
    animation: glowing2 2s infinite;
}

.ttt_cell {
    font-family: "Orbitron", sans-serif;
}