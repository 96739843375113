.experience-wrapper {
    width: 400px;
    height: 90px;
    border: 1px solid white;
}

.experience-normal {
    background-color: rgba(255, 255, 255, 0.1);
}

.experience-highlight {
    background-color: rgba(123, 232, 152, 0.2);
}


.experience-wrapper p {
    margin: 0;
    color: white;
}

.experience-text-wrapper {
    position: absolute;
    width: calc(100% - 110px);
    right: 0;
    text-align: left;
    padding: 0 20px;
    padding-top: 20px;
}

.experience_img {
    width: 50px;
    position: absolute;
    left: 20px;
    top: 20px;
}

.experience_comp {
    font-weight: bold;
}

.experience_title {
    font-size: 14px;
}

.experience_date {
    font-size: 12px;
}



@media only screen and (max-width: 550px) {
    .experience-wrapper {
        height: 78px;
    }

    .experience-text-wrapper {
        padding-top: 13px;
    }

    .experience_img {
        top: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .experience-wrapper {
        height: 72px;
        border: none;
    }

    .experience_comp {
        font-size: 14px;
    }

    .experience_img {
        top: 10px;
    }
}