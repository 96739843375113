:root {
    --black: #000;
    --white: #FFF;

    --dark: #111;
    --regular: #70e074;
    --light: #A7CCED;

    --font-primary: #FFF;
    --font-info: #444;
}